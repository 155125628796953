import React from "react";

const Stip = () => {
  return (
    <div className="bg-dark text-light text-center py-4 w-100">
      <p className="container my-auto">
        © Copyright 2022 Universo Magnanimo - All Rights Reserved.
      </p>
    </div>
  );
};

export default Stip;
